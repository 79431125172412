import { Carousel as ArkCarousel } from "@ark-ui/react";
import { Flex } from "@zenchef/styled-system/jsx";
import { aromaCarousel } from "@zenchef/styled-system/recipes";
import React, { useRef, useLayoutEffect, useState } from "react";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

export interface CarouselProps {
  children: React.ReactNode;
  slidesPerView?: number;
  loop?: boolean;
  dynamicPerView?: boolean;
}

const Carousel = ({
  children,
  slidesPerView = 1,
  loop = false,
  dynamicPerView = false,
}: CarouselProps) => {
  const itemGroupRef = useRef<HTMLDivElement>(null);
  const viewportRef = useRef<HTMLDivElement>(null);

  const classes = aromaCarousel();

  const [groupWidth, setGroupWidth] = useState(0);
  const [dynamicPerViewCount, setDynamicPerViewCount] = useState(slidesPerView);

  useLayoutEffect(() => {
    function handleResize() {
      const firstChild = itemGroupRef.current?.firstElementChild as HTMLElement;
      if (!firstChild) return;
      const itemWidth = firstChild.offsetWidth;
      if (dynamicPerView) {
        const viewportWidth = viewportRef.current?.offsetWidth ?? 0;

        const maxSlidesCount = Math.floor(viewportWidth / itemWidth);

        const slidesCount = Math.max(
          Math.min(React.Children.count(children), maxSlidesCount),
          1,
        );

        setDynamicPerViewCount(slidesCount);
        setGroupWidth((itemWidth + 8 || 0) * slidesCount);
      } else {
        setGroupWidth((itemWidth + 8 || 0) * slidesPerView);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dynamicPerView, slidesPerView]);

  const perViewCount = dynamicPerView ? dynamicPerViewCount : slidesPerView;

  const indicators = Array.from({
    length: Math.ceil(React.Children.count(children) / perViewCount),
  });

  return (
    <ArkCarousel.Root
      slidesPerView={perViewCount}
      loop={loop}
      className={classes.root}
    >
      <ArkCarousel.Viewport className={classes.viewport} ref={viewportRef}>
        <ArkCarousel.ItemGroup
          className={classes.itemGroup}
          ref={itemGroupRef}
          style={{
            width: groupWidth,
          }}
        >
          {React.Children.map(children, (child, index) => (
            <ArkCarousel.Item
              key={index}
              index={index}
              className={classes.item}
            >
              {child}
            </ArkCarousel.Item>
          ))}
        </ArkCarousel.ItemGroup>
      </ArkCarousel.Viewport>
      {indicators.length > 1 ? (
        <Flex className={classes.controlWrapper}>
          <ArkCarousel.Control className={classes.control}>
            <ArkCarousel.PrevTrigger
              className={classes.trigger}
              asChild
              tabIndex={0}
            >
              <Button hierarchy="brand-reversed-subtler" iconOnly>
                <Icon name="arrow-narrow-left" />
              </Button>
            </ArkCarousel.PrevTrigger>
            <ArkCarousel.IndicatorGroup className={classes.indicatorGroup}>
              {indicators.map((_, index) => (
                <ArkCarousel.Indicator
                  key={index}
                  index={index}
                  className={classes.indicator}
                />
              ))}
            </ArkCarousel.IndicatorGroup>
            <ArkCarousel.NextTrigger
              className={classes.trigger}
              asChild
              tabIndex={0}
            >
              <Button hierarchy="brand-reversed-subtler" iconOnly>
                <Icon name="arrow-narrow-right" />
              </Button>
            </ArkCarousel.NextTrigger>
          </ArkCarousel.Control>
        </Flex>
      ) : null}
    </ArkCarousel.Root>
  );
};

export default Carousel;
