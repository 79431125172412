import { NumberInput as ArkNumberInput } from "@ark-ui/react";
import {
  AromaNumberInputVariantProps,
  aromaNumberInput,
} from "@zenchef/styled-system/recipes";
import { ElementRef, forwardRef, useState } from "react";
import Icon from "../Icon/Icon";
import { FieldProps } from "../../utils/types";

type CoreNumberInputProps = AromaNumberInputVariantProps &
  ArkNumberInput.RootProps &
  Pick<FieldProps, "testId"> & {
    activeTrigger?: "increment" | "decrement" | undefined;
  };

/**
 * CoreNumberInput
 * @returns Styled number input implemented with arkui.
 */
const CoreNumberInput = forwardRef<ElementRef<"div">, CoreNumberInputProps>(
  ({ activeTrigger, testId, ...propsWithVariants }, ref) => {
    const [variants, props] =
      aromaNumberInput.splitVariantProps(propsWithVariants);
    const classes = aromaNumberInput(variants);
    const testIds = testId
      ? {
          root: testId,
          input: `${testId}-input`,
          incrementTrigger: `${testId}-increment-trigger`,
          decrementTrigger: `${testId}-decrement-trigger`,
        }
      : {};

    return (
      <ArkNumberInput.Root
        className={classes.root}
        ref={ref}
        data-testid={testIds.root}
        {...props}
      >
        <ArkNumberInput.Control className={classes.control}>
          <ArkNumberInput.DecrementTrigger
            className={classes.trigger}
            data-active={activeTrigger === "decrement" || undefined}
            data-testid={testIds.decrementTrigger}
          >
            <Icon name="minus" />
          </ArkNumberInput.DecrementTrigger>
          <ArkNumberInput.Input
            className={classes.input}
            data-testid={testIds.input}
          />
          <ArkNumberInput.IncrementTrigger
            className={classes.trigger}
            data-active={activeTrigger === "increment" || undefined}
            data-testid={testIds.incrementTrigger}
          >
            <Icon name="plus" />
          </ArkNumberInput.IncrementTrigger>
        </ArkNumberInput.Control>
      </ArkNumberInput.Root>
    );
  },
);

export type NumberInputProps = Omit<
  CoreNumberInputProps,
  "value" | "onValueChange" | "activeTrigger"
> & { value?: number; onValueChange: (value: number) => void };

/**
 * Example usage :
 * <NumberInput
 *    value={0}
 *    onValueChange={setValue}
 *    min={min}
 *    max={max}
 *    size='medium'
 *    reversed
 *  />
 * @returns Controlled number input with increment and decrement buttons being set to active when using keyboard controls
 */
const NumberInput = forwardRef<ElementRef<"div">, NumberInputProps>(
  ({ onValueChange, onFocusChange, value, ...props }, ref) => {
    const [activeTrigger, setActiveTrigger] = useState<
      "increment" | "decrement" | undefined
    >(undefined);

    const handleChange: CoreNumberInputProps["onValueChange"] = ({
      valueAsNumber,
    }) => {
      if (value !== undefined && valueAsNumber !== value) {
        if (valueAsNumber > value) {
          setActiveTrigger("increment");
        } else {
          setActiveTrigger("decrement");
        }
      }
      onValueChange(valueAsNumber);
    };

    const handleFocusChange: CoreNumberInputProps["onFocusChange"] = (e) => {
      setActiveTrigger(undefined);
      onFocusChange?.(e);
    };

    return (
      <CoreNumberInput
        ref={ref}
        value={value !== undefined ? String(value) : undefined}
        onValueChange={handleChange}
        onFocusChange={handleFocusChange}
        activeTrigger={activeTrigger}
        {...props}
      />
    );
  },
);

export default NumberInput;
