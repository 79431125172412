import { cx } from "@zenchef/styled-system/css";
import { HTMLStyledProps, styled } from "@zenchef/styled-system/jsx";
import { AromaTagVariantProps, aromaTag } from "@zenchef/styled-system/recipes";
import Icon from "../Icon/Icon";

type Removable =
  | { removable?: false; onRemove?: () => void }
  | { removable: true; onRemove: () => void };

export type TagProps = HTMLStyledProps<"span"> &
  AromaTagVariantProps &
  Removable;

const Tag = ({
  className,
  children,
  removable,
  onRemove,
  ...props
}: TagProps) => {
  const [aromaTagVariantProps, restProps] = aromaTag.splitVariantProps(props);
  const classes = aromaTag(aromaTagVariantProps);
  return (
    <styled.span className={cx(classes.container, className)} {...restProps}>
      {children}
      {removable && (
        <button className={classes.removeTrigger} onClick={onRemove}>
          <Icon name="x" />
        </button>
      )}
    </styled.span>
  );
};

export default Tag;
