import {
  AromaTooltipVariantProps,
  aromaTooltip,
} from "@zenchef/styled-system/recipes";
import { Tooltip as ArkTooltip, Portal } from "@ark-ui/react";
import { Fragment } from "react";

interface TooltipContentProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
}

export const AromaPlacements = [
  "top-center",
  "top-left",
  "top-right",
  "center-left",
  "center-right",
  "bottom-center",
  "bottom-left",
  "bottom-right",
] as const;

type AromaPlacement = (typeof AromaPlacements)[number];
type ArkUiPlacement = NonNullable<
  ArkTooltip.RootProps["positioning"]
>["placement"];

export interface TooltipProps
  extends AromaTooltipVariantProps,
    TooltipContentProps,
    ArkTooltip.RootProps {
  children?: React.ReactNode;
  placement?: AromaPlacement;
  portalled?: boolean;
  asChild?: boolean;
  zIndex?: number;
}

const MapAromaPlacementArkPlacement: Record<AromaPlacement, ArkUiPlacement> = {
  "top-center": "top",
  "top-left": "top-end",
  "top-right": "top-start",
  "center-left": "left",
  "center-right": "right",
  "bottom-center": "bottom",
  "bottom-left": "bottom-end",
  "bottom-right": "bottom-start",
};

const TooltipContent = ({ title, description }: TooltipContentProps) => {
  return (
    <>
      {title && <strong>{title}</strong>}
      {description && <div>{description}</div>}
    </>
  );
};

const Tooltip = ({
  children,
  title,
  description,
  placement = "top-center",
  positioning,
  portalled = true,
  asChild,
  zIndex,
  ...props
}: TooltipProps) => {
  const [aromaTooltipVariantProps, restProps] =
    aromaTooltip.splitVariantProps(props);

  const classes = aromaTooltip(aromaTooltipVariantProps);
  if (!children) {
    return (
      <div className={classes.content}>
        <TooltipContent title={title} description={description} />
      </div>
    );
  }

  const computedPositioning: ArkTooltip.RootProps["positioning"] = placement
    ? {
        placement: MapAromaPlacementArkPlacement[placement],
        offset: {
          mainAxis: 4,
          crossAxis: placement.includes("center")
            ? undefined
            : placement.includes("left")
              ? 4
              : -4,
        },
        ...positioning,
      }
    : positioning;

  const PortalTag = portalled ? Portal : Fragment;

  return (
    <ArkTooltip.Root
      positioning={computedPositioning}
      openDelay={0}
      closeDelay={0}
      {...restProps}
    >
      <ArkTooltip.Trigger className={classes.trigger} asChild={asChild}>
        {children}
      </ArkTooltip.Trigger>
      <PortalTag>
        <ArkTooltip.Positioner
          style={{ zIndex }}
          className={classes.positioner}
        >
          <ArkTooltip.Content className={classes.content}>
            <TooltipContent title={title} description={description} />
          </ArkTooltip.Content>
        </ArkTooltip.Positioner>
      </PortalTag>
    </ArkTooltip.Root>
  );
};

export default Tooltip;
